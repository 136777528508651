import React from 'react'
import { graphql } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const DefaultPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body
}) => {
  // /../../static/images/**** */.jpg
  const searchString = "../../static";

  let img = featuredImage;

  if (featuredImage && featuredImage.length > 0)
    img = featuredImage.replace('../../static', '')

  return (
    <main className="DefaultPage">
      {/* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      /> */}

      <section className="section">
        <div className="container">
          <Content source={body} />
          {img && img !== "" &&
            <img style={{
              display: 'block',
              width: '100%',
              height: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto'
            }} src={img} alt={title} />
          }
          {/* <SVGIcon src="/images/calendar.svg" /> */}
        </div>
      </section>
    </main>
  )

}

const DefaultPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    showMenu={false}
  >
    <DefaultPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default DefaultPage

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
